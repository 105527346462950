.series {
  background-color: black;
  height: 100vh;
	--common-paddding-left: 70px;
	--common-font-size: 40px;
}
.section-content .series {
  height: calc(100vh - var(--ctv_nav_height));
}
.series .description {
  height: 45%;
  position: relative;
}
.series .image-wrapper {
  height: 100%;
  width: 55%;
  float: right;
  position: relative;
}
.series .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.series .description .details {
  padding-left: var(--common-paddding-left);
  padding-top: 30px;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  font-size: var(--common-font-size);
  z-index: 9;
}
.series .description .details p {
  margin: 0;
}
.series .description .details .title {
  padding-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.series .description .details .meta-data {
  font-size: 50%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.series .description .details .summary {
  padding-top: 10px;
  font-size: 60%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.series .description .item-play {
  position: absolute;
  bottom: 20px;
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.series .description .item-play > span {
	width: max-content;
	min-width: 150px;
	height: 40px;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
	padding: 20px 50px;
	background-color: red;
  margin-right: 30px;
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 10px rgb(0 0 0 / 65%);
}
.series .description .item-play .arrow-right {
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-left: 15px solid white;
	display: inline-block;
	margin-right: 15px;
	margin-top: 4px;
	vertical-align: middle;
}
.series .description .shodow {
  position: absolute;
  top: 0px;
  left: -1px;
  width: 100%;
  height: 100%;
  box-shadow: inset 40px -20px 48px 0px rgb(0 0 0);
}
.series .below-section {
  padding: 0 var(--common-paddding-left);
  font-size: var(--common-font-size);
  overflow: hidden;
  height: 55%;
  box-sizing: border-box;
}
.series .season-list {
  display: flex;
  overflow-x: scroll;
  align-items: center;
}
.series .season-list .season-wrapper {
  padding: 10px 30px 10px 0px;
}
.series .season-list .season-wrapper .season {
  display: block;
  width: max-content;
  padding-bottom: 4px;
  cursor: pointer;
}
.series .season-list .season-wrapper .season.active {
  border-bottom: 2px solid red;
}
.series #standard-grid.grid-view .swiper-container,
.series #standard-grid.grid-view .slick-slider {
  padding-left: 0;
  margin-left: 0;
}



/* WEB */
.web-route-container .series {
  height: calc(100vh - var(--nav-height));
}
.web-route-container .non-section-content .series .description .details {
  padding-top: 50px;
}
.web-route-container .series .description {
  height: 50%;
}
.web-route-container .series .below-section {
  height: 50%;
}


@media screen and (max-width: 1920px) {
  .series {
    --common-paddding-left: 40px;
    --common-font-size: 36px;
  }
}

@media screen and (max-width: 1536px) {
  .series {
    --common-font-size: 32px;
  }
  .series .description .item-play {
    font-size: 18px;
  }
  .series .description .item-play > span {
    height: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .series {
    --common-paddding-left: 30px;
    --common-font-size: 24px;
  }
  .series .description .item-play {
    font-size: 16px;
  }
  .series .description .item-play > span {
    padding: 10px 40px;
  }
}



/* Mobile */
.web-route-container-mobile .series {
  position: absolute;
  top: 70px;
  overflow: scroll;
  --common-paddding-left: 15px;
  --common-font-size: 20px;
}
.web-route-container-mobile .series .description {
  height: unset;
}
.web-route-container-mobile .series .image-wrapper {
  width: 100%;
  height: 250px;
  float: unset;
  background: white;
}
.web-route-container-mobile .series .description .details,
.web-route-container-mobile .non-section-content .series .description .details {
  padding: var(--common-paddding-left);
  padding-top: 100px;
  position: relative;
  width: 100%;
}
.web-route-container-mobile .series .below-section {
  height: unset;
}
.web-route-container-mobile .series .description .item-play {
  top: 20px;
  bottom: unset;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-around;
}
.web-route-container-mobile .series .description .item-play div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.web-route-container-mobile .series .description .item-play div > span {
  margin: 0;
}
.web-route-container-mobile .series .item-play div > span.mt-10 {
  margin-top: 10px;
}
.web-route-container-mobile .series .episode {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin: 10px 0;
}
.web-route-container-mobile .series .episode .img-video {
  width: 140px;
}
.web-route-container-mobile .series .episode .img-video img {
  height: 100%;
  width: 100%;
}
.web-route-container-mobile .series .episode .episode-des {
  padding-left: 10px;
}
.web-route-container-mobile .series .episode .episode-des .title {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.web-route-container-mobile .series .episode .episode-des .sub-title {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 60%;
}
.web-route-container-mobile .series .episode .episode-des .summary {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 70%;
}
.web-route-container-mobile .series .season-list {
  border-bottom: 1px solid white;
}
