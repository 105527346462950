#fullscreen{
  height: 100%;
}

/*Preview Css */

#fullscreen * div#previewDescription{
  display: none;
}

#fullscreen > div#previewSection{
  position: fixed;
  height: 100%;
  width: 100%;
  margin: auto;
  top: 0px;
  z-index: 0;
}

#fullscreen > div#previewImage{
  position: absolute;
  right:0px;
  top:0px;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#fullscreen * div#previewVideo{
  position: absolute;
  right:0px;
  top:0px;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#fullscreen * div#previewShadow{
  display:none;
}
#fullscreen * div#previewImage{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#fullscreen-grid{
  width: 100%;
  /* height: 100%; */
  /* bottom: 0; */
  z-index: 15;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
}

#fullscreen-grid * .swipper-wrapper{
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5) 10%, rgba(0, 0, 0, .7) 100%);
}

#fullscreen-grid * * .titleHolder{
  display: none;
}

#fullscreen * * .detail-swiper:hover > div.titleHolder{
  display:block;
}


#fullscreen * * span.swiper-slide:hover + span.swiper-slide > div.spotlight > h2{
  display: none;
}

#fullscreen * * span.swiper-slide:hover + span.swiper-slide > div.spotlight > div.ctaHolder{
  display: none;
}

#fullscreen * * div.titleHolder > p {
  text-align: center;
  width: 100% !important;
  height: 48px;
  line-height: 1.2;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}


#fullscreen * * div.progress-bar {
  width: 90%;
  background-color: #e0e0e0;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: center;
  height:4px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.2);
  margin-bottom: 10px;
  position: absolute;
  z-index: 20;
  bottom: 2px;
}

#fullscreen * * .progress-bar-fill {
  display: block;
  height: 4px;
  background-color: green;
  border-radius: 3px;
  transition: width 500ms ease-in-out;
}

#fullscreen * * .vjs-poster{
  background-size: cover !important;
}