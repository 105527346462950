body {
	--video_inline_item_width: 18vw;
	--video_inline_item_height: calc(var(--video_inline_item_width) * 0.5625);
	--video_inline_title_height: 80px;
	--ctv_nav_height: 120px;
}
/* Common Css*/
.white-bg {
	background-color: white;
}

.font-black {
	color: black;
}

.scroll-auto-h-100 {
	overflow: auto;
	height: 100%;
}

.overflow-hidden {
	overflow: hidden;
}

video:focus {
	outline: none;
}
.with-modal .no-logo-div {
	height: 50px;
}
.text-center {
	text-align: center;
}
.thumbLink img.img-thumb {
	object-fit: cover;
	object-position: center;
}
.videoFull video {
	object-position: center;
	object-fit: contain !important;
}
/* CSS for navbar auto content width */
.slider-box.nav-view > .swiper-container.swiper-container-horizontal {
    width: "intrinsic";           /* Safari/WebKit uses a non-standard name */
    width: "-moz-max-content";    /* Firefox/Gecko */
    width: "-webkit-max-content"; /* Chrome */
    width: max-content;
}
.videoFull .vjs-control-bar {
	display: none;
}
/* CSS for Video player -> Inline Menu */
.video-inlineMenu {
	position: absolute;
	bottom: 0px;
	z-index: 99;
	width: 100%;
	overflow: hidden;
}
.video-inlineMenu.closedMenu {
	bottom: 95px;
}
.video-inlineMenu.closingNow {
	bottom: -40px;
}
@-moz-document url-prefix() {
	/* firefox */
	.video-inlineMenu.closedMenu {
		bottom: 40px;
	}
}
@media not all and (min-resolution:.001dpcm) {
	/* safari */
	@supports (-webkit-appearance:none) {
		.video-inlineMenu.closedMenu {
			bottom: 40px;
		}
		span.icon-hollow_arrow {
			position: absolute;
			top: 40px !important;
		}
	}
}
.videoFull .video-play-extra-button {
	position: absolute;
	top: calc(50% - 55px);
  left: calc(50% - 36px);
}
.videoFull .video-play-extra-button img {
	cursor: pointer;
	background-color: #fff;
	border-radius: 50%;
	padding: 4px;
}
.videoFull .bumpable-ima-ad-container div:first-child {
	/* lonlinear google ad */
	top: 200px;
	height: 100px !important;
}
.videoFull .bumpable-ima-ad-container div:first-child iframe{
	/* lonlinear google ad */
	height: 100px;
}
.videoFull .bumpable-ima-ad-container .ima-controls-div {
	display: none
}
.videoFull .idle-live-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
	position: absolute;
	top: 0;
	z-index: 10;
	background-color: black;
}
.video-inlineMenu .button-toggle {
	text-align: center;
}
.video-inlineMenu .button-toggle img{
	height: 50px;
    cursor: pointer;
    width: 50px;
    background: #0000007a;
    padding: 12px;
    border-radius: 50%;
}
.video-inlineMenu .menu{
	padding: 30px;
	margin-top: 30px;
	background: #f3f1f1;
	width: 100%;
	/* Height = item height + title height + padding(60) */
	height: calc(var(--video_inline_item_height) + var(--video_inline_title_height) + 60px); 
	transition: height 450ms;
    box-sizing: border-box;
}
.video-inlineMenu .menu.inlineMenuAnimation {
	overflow: hidden;
}
.video-inlineMenu .menu .swiper-container {
	overflow: inherit;
}
.video-inlineMenu .menu .swiper-container .swiper-slide {
	overflow: inherit;
}
.video-inlineMenu .menu .swiper-slide {
	background: none;
}
.video-inlineMenu .menu.not-visible {
	padding: 0;
	margin: 0;
	height: 0;
}
.video-inlineMenu.closingNow .menu {
	height: 0;
}
.video-inlineMenuItem {
	width: var(--video_inline_item_width) !important;
}
.video-inlineMenu .video-inlineMenuItem img {
	height: var(--video_inline_item_height);
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
	display: block;
	text-align: center;
	object-fit: cover;
}
.video-inlineMenu .video-inlineMenuItem span {
	border-radius: 5px;
	width: 100%;
	display: inline-block;
}
.video-inlineMenu .video-inlineMenuItem span:hover,
.video-inlineMenu .video-inlineMenuItem span.selected {
	transform: scale(1.08);
	box-shadow: 0 6px 10px 10px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.video-inlineMenu .video-inlineMenuItem span.limited {
	opacity: 0.5;
	cursor: not-allowed;
}
.video-inlineMenu .video-inlineMenuItem .title{
	height: var(--video_inline_title_height);
	text-align: center;
	color: black;
	margin: 0 5px;
	overflow: hidden;
}
.video-inlineMenu .video-inlineMenuItem .title p {
	overflow: hidden;
	margin: 15px 0 0 0;
	font-size: 24px;
    line-height: 32px;
}

/* Style to fix nav bar */
.fix-nav > div:first-child {
	position: fixed;
	top: 0;
	width: 100%;
}
.fix-nav > .nav-view + div {
	margin-top: 140px;
}

/* in case of overlay remove fixed from nav-bar */
#overlay + .fix-nav > .nav-view {
    position: relative;
}
#nav-bar .disable-header-link,
#web-nav-bar .disable-header-link {
	pointer-events: none;
}

.vjs-error .vjs-error-display:before {
	content: "";
	margin: 0;
}

/* Css for subscription modal */
.subscribeScreen-wrapper {
	min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Video player  */
.prevThumbnail img {
    height: 150px;
    width: 270px;
}
.prevThumbnail {
    width: 270px;
}
.prevThumbnail p {
    font-size: 16px;
}
.backBtn {
    width: 60px;
    height: 60px;
}
.metering-counter, .video-title {
    background-color: #040404;
    width: 240px;
    height: 65px;
    border-radius: 10px;
    text-align: center;
    line-height: 60px;
}
.video-title p {
    margin: 0px 10px !important;
    font-size: 20px;
}
.metering-counter span {
    font-size: 24px;
}
.image-view .metering-counter span {
	position: static;
}

.ellipsis p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* Spotlight theme*/
.spotlight * * div.img-video img{
	border-radius: 10px;
}
#spotlight-grid .swiper-slide.detail-swiper,
#standard-grid.grid-view .swiper-slide.detail-swiper{
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
}
#spotlight-grid .spotlight > .ctaHolder p {
	margin: 0 auto;
}

#spotlight-grid .spotlight.grid-view > * {
	padding: 0 60px;
	margin: 0;
}

#spotlight-grid .spotlight.grid-view > *:first-child {
	padding-top: 25px;
	font-size: 34px;
}
#spotlight-grid .swiper-slide:first-of-type .spotlight.grid-view > *:first-child {
	padding-top: 0px;
}

#spotlight-grid .spotlight.grid-view > .swiper-container {
	height: 100%;
	padding-top: 25px;
	overflow: visible;
}

#spotlight-grid > .swiper-container.swiper-container-vertical > div > span,
#fullscreen-grid > .swiper-container.swiper-container-vertical > div > span {
	min-height: "intrinsic";           /* Safari/WebKit uses a non-standard name */
    min-height: "-moz-max-content";    /* Firefox/Gecko */
    min-height: "-webkit-max-content"; /* Chrome */
	min-height: max-content;
	max-height: "intrinsic";           /* Safari/WebKit uses a non-standard name */
    max-height: "-moz-max-content";    /* Firefox/Gecko */
    max-height: "-webkit-max-content"; /* Chrome */
    max-height: max-content;
	height: fit-content !important;
}

/* Big-title */
#spotlight-section > #previewSection > #previewDescription .previewTitle{
	font-size: 40px;
	font-weight: bold;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

/*	Subtitle */
#spotlight-section > #previewSection > #previewDescription .previewSubtitle{
	font-size: 27px;
	overflow: hidden;
	height: auto;
}
/*Description*/
#spotlight-section > #previewSection > #previewDescription #previewSummary{
	font-size: 27px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	margin-top: 0;
}

#spotlight-grid .spotlight.grid-view > .swiper-container a.thumbLink img.img-thumb {
	max-width: 100%;
}

/* Fullscreen Css (fixes)*/
.swiper-container-vertical.vert-container>.swiper-wrapper {
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5) 10%, rgba(0, 0, 0, .7) 100%);
}

#fullscreen-grid {
	height: 100vh;
}

#fullscreen-grid .spotlight > .ctaHolder p {
	margin: 0 auto;
}

#fullscreen-grid .spotlight.grid-view > * {
	padding: 0 60px;
	margin: 0;
}

#fullscreen-grid .spotlight.grid-view > *:first-child {
	padding-top: 32px;
	font-size: 35px;
}

#fullscreen-grid .spotlight.grid-view > .swiper-container {
	height: 100%;
	padding-top: 18px;
	overflow: visible;
}

#fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
	font-size: 29px;
	height: auto;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

/*Standard Grid View */
#standard-grid.grid-view .swiper-container {
	padding: 22px 100px 0;
	margin-bottom: 20px;
}
#standard-grid.grid-view .ctaHolder {
	font-size: 42px;
	padding: 50px 0 10px 100px;
	margin: 0;
}
#standard-grid.grid-view .ctaHolder p {
	margin: 0px;
}
#standard-grid.grid-view h2 {
	padding: 50px 100px 10px;
	font-size: 42px;
	margin: 0;
}
#standard-grid.grid-view .swiper-container,
#standard-grid.nothumbBox.grid-view .swiper-container {
	/* max-height: 445px; */
	height: auto;
}
#standard-grid.grid-view.portrait-view .swiper-container,
#standard-grid.nothumbBox.grid-view.portrait-view .swiper-container {
	/* max-height: 640px; */
	height: auto;
}
#standard-grid.grid-view .titleHolder {
	display: flex;
    justify-content: space-evenly;
	align-items: center;
	max-height: 90px;
    margin-top: 40px;
}
#standard-grid.grid-view.portrait-view .titleHolder {
	max-height: 140px;
}
#standard-grid.grid-view .titleHolder p,
#standard-grid.nothumbBox.grid-view .titleHolder p {
	font-size: 36px;
	display: -webkit-box;
	height: auto;
	max-height: 100px;
	margin: 0px 0 20px 0;
	flex: 1;
	display: flex;
	justify-content: center;
}
#standard-grid.grid-view.portrait-view .titleHolder p {
	max-height: 140px;
	margin: 0px 0 10px 0;
}
#standard-grid.grid-view .titleHolder a img.icon {
    width: 70px;
    height: 70px;
	background-size: 70px 70px;
	margin-left: 20px;
}
#standard-grid.grid-view .swiper-container .thumbLink:hover {
	transform: scale(1.07);
}
#standard-grid.grid-view .swiper-container .thumbLink:hover + .titleHolder p {
	transform: scale(1.07);
	text-shadow: 0 3px 20px rgba(0,0,0, .4);
}

/* Detail theme css*/

.detail-view-cls {
	position: relative;
}

.detail-view-cls .detail-view .swiper-wrapper .img-video {
	width: 100%;
	max-width: 100%;
}

.detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
	width: 35vw;
	max-width: 100%;
	margin-left: 158px;
}
.detail-view-cls .detail-view .swiper-slide.swiper-slide-active{
	transform: scale(1.06);
  -webkit-transform: scale(1.06);
	transform: perspective(1px) scale(1.06);
}

.detail-view-cls .swiper-container-vertical .detailContent {
	width: calc(65vw - 160px);
	padding: 75px 75px 0;
	box-sizing: border-box;
}

.detail-view-cls .swiper-container-vertical .detailContent .saveIcon {
	width: calc(65vw - 160px);
	padding-right: 0;
	bottom: 100px;
}

.detail-view-cls .swiper-container-vertical .detailContent .saveIcon img {
	padding: 30px 37px;
    border: 0;
    width: 70px;
}

.detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
	display: block;
	text-align: center;
	font-size: 29px;
	height: auto;
	max-height: fit-content;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

.detail-view-cls .detail-view.portrait-view .swiper-wrapper .titleHolder {
	width: 100%;
	position: relative;
	top: -1px;
}

.detail-view-cls .detail-view .swiper-container.swiper-container-vertical {
	padding-top: 75px;
}

.detail-view-cls .detail-view.portrait-view .swiper-wrapper > .detail-swiper {
	width: 475px;
}

.detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
	padding-bottom: 0;
}

.detail-view-cls > div > h2 {
	top: 276px;
    left: -139px;
    font-weight: bold;
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    height: auto;
	width: 465px;
	-webkit-transform: rotate(270deg);
	-moz-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	font-size: 50px;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder h4,
.detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
	padding-right: 0;
	font-size: 42px;
	font-weight: bold;
	max-height: fit-content;
}

.detail-view-cls .swiper-container-vertical .detailContent .ctaHolder p {
	margin-top: 0;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p {
	padding-right: 0;
	font-size: 34px;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p.subtitle {
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	max-height: min-content;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p.summaryDescription {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	overflow: hidden;
	max-height: max-content !important;
	margin-bottom: 10px;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}
.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder .extra-specs,
.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder .specs {
	display: flex;
	justify-content: start;
	color: rgba(255, 255, 255, 0.75);
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder .extra-specs p:nth-child(1) {
	width: 200px;
}
.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder .extra-specs p:nth-child(2) {
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: table-cell;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder .specs p {
	margin-right: 20px;
}

.detail-view-cls .detail-view .swiper-wrapper .titleHolder {
	height: 100px;
	position: relative;
	top: -1px;
}
.img-banner {
    height: auto;
}

/* Jumbo view */
.jumbo-view .ctaHolder {
	padding: 10px 0 0 135px;
}
.jumbo-view h2 {
	width: 400px;
	font-size: 42px;
}
.jumbo-view .ctaHolder p,
.jumbo-view .titleHolder p {
	font-size: 36px;
}
.jumbo-view .lock-icon {
	display: inline-block;
    margin-bottom: -2px;
    padding-right: 5px;
    width: 30px;
}
.jumbo-view .img-video {
	max-width: unset !important;
}
.jumbo-view .swiper-container {
	padding: 25px 135px 20px;
}
.jumbo-view .titleHolder a img.icon {
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
}
.jumbo-view .swiper-container,
.jumbo-view.portrait-view .swiper-container {
    height: auto;
}
.jumbo-view .img-video {
		height: 0px;
		padding-bottom: 56.6%;
}
.jumbo-view .titleHolder {
		height: 135px;
		padding: 10px;
    overflow: hidden;
}
.jumbo-view .titleHolder p,
.jumbo-view.portrait-view .titleHolder p {
	max-height: unset;
}
/* .jumbo-view.portrait-view .img-video {
		height: 0px;
		padding-bottom: 133%;
} */
.jumbo-view.portrait-view .titleHolder {
    height: 180px;
}
.jumbo-view .ctaHolder p {
	margin-bottom: 10px;
}
#overlay + .fix-nav > .nav-view {
	position: relative;
}

.font-black .crossBtn::after, .font-black .crossBtn::before  {
	background-color: black;
}

#overlay.payment  {
	background-color: #F2F2f2;
}

.green-btn{
	background: #4CAF50;
    outline: none;
    border: none;
    color: white;
    font-size: 21px;
    padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}
.green-btn:hover {
	background-color: #090;
}
.green-btn:disabled {
	background-color: #e7e7e7;
	color: black;
	cursor: not-allowed;
}

/* Close button icon */
.crossBtnIcn {
	position: absolute;
	left: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.6;
}
.crossBtnIcn:hover {
	opacity: 1;
}
.crossBtnIcn:before, .crossBtnIcn:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}
.crossBtnIcn:before {
	transform: rotate(45deg);
}
.crossBtnIcn:after {
	transform: rotate(-45deg);
}

/* Error div*/
.error-div {
	position: fixed;
	top: 0;
	left: 0;
	text-align: center;
	background-color: #f44336;
	color: white;
	padding: 5px;
	width: 100vw;
	z-index: 101;
}

.error-div .crossBtnIcn {
	left: unset;
	right: 15px;
	top: 7px;
}
.error-div .crossBtnIcn:before, .error-div .crossBtnIcn:after {
	background-color: #fff;
}

/* Loader */
.payment-loader {
	height: 100vh;
    width: 100vw;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
	align-items: center;
	background-color: white;
	opacity: 0.85;
	z-index: 100;
	flex-direction: column;
}

.payment-loader .loader-text {
	color: black;
}
/* Registration wall  */
.logInScreen .btn-email.continue-btn,
.logInScreen .btn-fbIcon.metro {
	text-align: center;
}
.overflow-scroll {
	overflow: scroll;
}
.overflow-scroll-auto {
	overflow: auto;
}
.user-access .logInScreen .subscriberHeader {
	margin: 40px 0;
}
.user-access .logInScreen .subscriberHeader a {
	padding: 30px;
    border-radius: 8px;
    min-width: 600px;
    box-sizing: border-box;
	display: inline-block;
	font-size: 36px;
}
.user-access .logInScreen .subscriberHeader a:hover {
	transform: scale(1.1);
}
.user-access .logInScreen .btn-fbIcon {
	font-size: 36px;
	padding: 25px 30px;
    min-width: 600px;
}
.user-access .logInScreen .btn-fbIcon img{
	width: 60px;
    height: 60px;
    background-size: 60px;
    vertical-align: middle;
    margin-right: 15px;
}
.user-access .logInScreen a.btn-email {
	padding: 30px;
    min-width: 600px;
    box-sizing: border-box;
    font-size: 36px;
}
.user-access .logInScreen a.btn-email .mail-icon{
	background-size: 60px 50px;
	height: 50px;
	width: 60px;
	margin-right: 15px;
    vertical-align: bottom;
}
.user-access .logInScreen p {
	margin-bottom: 60px;
	font-size: 42px;
}
.user-access .logInScreen .termsOfUse {
	position: static;
	margin: 40px 0;
}
.user-access .logInScreen .termsOfUse li a {
	font-size: 30px;
}
.user-access a.crossBtn {
	left: 20px;
}
.user-access a.crossBtn::after,
.user-access a.crossBtn::before {
	content: '';
    width: 40px;
    height: 5px;
}
.user-access a.crossBtn::after {
	transform: translate(8px,-2px) rotate(45deg);
}
.user-access a.crossBtn::before {
	transform: translate(8px,36px) rotate(-45deg);
}
.user-access .bckgrnds .videoBg video {
	height: 100%;
}
.user-access .logInScreen #account-form input,
.user-access .logInScreen #account-form .btnForgot a,
.user-access .logInScreen #account-form button {
	font-size: 26px;
}
.user-access .logInScreen #account-form .btnForgot {
	display: inline-block;
    position: relative;
    margin-top: 30px;
}
.user-access .logInScreen #account-form .btnForgot input {
	margin: 0;
}
.user-access .logInScreen #account-form .btnForgot a {
	min-width: 200px;
	position: absolute;
	margin: 0;
	right: -275px;
    padding: 12px 20px;
}
.user-access .logInScreen #account-form span.btnBox {
	display: inline-block;
	margin-top: 100px;
}
.user-access .logInScreen #account-form span.btnBox button{
	padding: 10px 20px;
	margin:	0 10px;
	min-width: 275px;
}
.user-access .logInScreen #account-form input {
	min-width: 550px;
}
.userLoginCntnt {
	min-height: calc(100vh - 100px);
    display: flex;
	flex-direction: column;
	align-content: center;
    justify-content: center;
}
.user-access .logInScreen a.btn-email .website-icon {
	background-image: url("../images/icons/web_white.png");
}
.user-access .logInScreen a.btn-email .website-icon{
	background-size: 50px 50px;
	height: 50px;
	width: 50px;
	margin-right: 15px;
	vertical-align: bottom;
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
}

.user-access .termsOfUse {
	position: relative;
	text-align: center;
}

.termsOfUse ul.mob_info {
	padding: 0;
}
.termsOfUse li {
	padding: 0px 5px;
}
.termsOfUse li.termsVertical {
	font-size: 20px;
}

/* Search section */
.search-section .search-row {
	padding: 50px;
	display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 50px;
	width: 90%;
}
.search-section .search-row .searchContent {
	width: unset;
    margin: 0;
}
.search-section .search-row .searchContent .titleHolder {
	width: auto;
	display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.search-section .search-row .searchContent .titleHolder p {
	height: auto;
	font-size: 36px;
	text-align: center;
	float: unset;
	width: 100%;
	margin: 1em 0;
}
.search-section .search-row .searchContent .img-video {
	height: 300px;
	padding-bottom: 0px;
}
.search-section .search-row .searchContent .img-video img {
	position: static;
}
.search-section .search-row .searchContent .titleHolder a img.icon {
    width: 70px;
    height: 70px;
	background-size: 70px 70px;
	margin: 1em 0 1em 10px;
}
.search-section .no-search-items{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    font-size: 20px;
}
.simulator-route-container .search-section .no-search-items span{
	margin-bottom: 120px;
}
/* Confirm modal css*/
.confirm-modal {
	background: rgba(0,0,0,0.92);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
	left: 0;
	display: flex;
    align-items: center;
	justify-content: center;
	color: black;
	z-index: 100;
}

.confirm-modal > div {
	background: transparent;
    width: 400px;
    height: 250px;
    border-radius: 15px;
    padding: 15px;
    color: white;
    text-align: center;
}

.confirm-modal p {
	font-size: 23px;
}

.confirm-modal button {
    margin-right: 20px;
    cursor: pointer;
    background-color: white;
    color: black;
    padding: 20px 45px;
    border-radius: 8px;
    outline: none;
    border: none;
	font-size: 22px;
	font-weight: 600;
}

.confirm-modal h3 {
	margin: 10px 0;
}

/* Subscription page */
#overlay.subscribe-page {
	overflow: auto;
}
.subscribeScreen-wrapper {
	padding: 30px 0;
}
.subscribeScreen .sub-item-details h4 {
	margin: 10px 0;
}

.subscribeScreen .subscription-option-div {
	display: flex;
	flex-direction: column;
	align-content: center;
	margin-bottom: 25px;
}

.subscribeScreen .subscription-option-div .trial-text {
	font-size: 22px;
	margin-top: 10px;
}

.subscribeScreen .sub-item-details > div {
	font-size: 22px;
}

.subscribeScreen .sub-item-cancel-button p {
	font-size: 22px;
}
.subscribeScreen span:last-child a.btn-trial {
	margin: 0;
}

.subscribeScreen .subscription-option-div a.btn-trial {
	min-width: 600px;
	margin: 0;
}

.subscribeScreen .sub-item-cancel-button  span > a.btn-trial{
	margin-top: 15px;
}

.subscribeScreen p.subscription-header-text {
	margin-bottom: 15px;
}

.subscribeScreen-wrapper .termsOfUse {
	position: relative;
	text-align: center;
}

.subscribeScreen-wrapper .termsOfUse > ul {
	margin-bottom: 0;
}

.subscribeScreen .sub-unlock-note {
	font-size: 18px;
}

.subscribeScreen  .restore-purchase {
	margin-top: 60px;
}

.subscribeScreen  .restore-purchase > a.btn-trial{
	min-width: auto;
    padding: 18px 40px;
}

.subscribeScreen-wrapper .disclaimer {
	padding: 30px 50px;
    text-align: center;
    font-size: 18px;
}

.subscribeScreen-wrapper .subscription-cntnt {
	min-height: calc(100vh - 190px);
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscribeScreen-wrapper .iapTitle {
	text-align: center;
	margin-bottom: 5px;
	font-size: 30px;
}

.icon-hollow_arrow.backBtnSpan {
	position: absolute;
	cursor: pointer;
}

/* show navbar In sim live, live*/
.transparent .slider-box.nav-view {
	opacity: 0.7;
	z-index: 201;
}

#overlay {
	top: 0;
}

.with-modal #nav-bar {
	position: fixed;
	top: 0;
	width: 100%;
}
.videoFull .icon-hollow_arrow.video_back{
	position: absolute;
}

/* Payment confirmation page */
.payment-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-page img {
	width: 200px;
	height: auto;
}

.payment-page .dur-price {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-page .pay-cnf-msg {
	font-size: 35px;
}

.payment-page  .redirect-text, .payment-page  .redirect-bck {
	font-size: 20px;
}

.payment-page  .redirect-bck {
	color: blue;
	cursor: pointer;
}

.image-scroll .swiper-slide.image-full {
	background-position: center center;
}

.icon-hollow_arrow a.backBtn{
	z-index: 100;
    background-image: url(../images/back.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	border-radius: 4px;
	width: 50px;
    height: 35px;
}

span.icon-hollow_arrow:before,
span.icon-hollow_arrow:after {
	content: unset;
}

span.icon-hollow_arrow{
	position: absolute;
	left: 20px;
	top: 20px;
}


/*vjs error display in the middle*/
.vjs-error .vjs-error-display .vjs-modal-dialog-content {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	font-size: 16px;
}

/* Feed error page*/
.feed-error {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: black;
}

.feed-error h1 {
	text-align: center;
	font-size: 45px;
}

.no-content-div {
	margin-top: 0 !important;
	background: black;
	height: 100vh;
}

#account-form input,
#account-form button,
.termsOfUse a,
.subscribeScreen-wrapper a {
	border: none;
	outline: none;
}

/* Style for data-privacy */
.data-privacy {
	padding: 50px;
	background-color: #ddd6d6;
	height: 100vh;
	box-sizing: border-box;
	color: #fff;
	overflow-y: scroll;
}
.data-privacy hr{
	background-color: #fff;
    height: 2px;
}
.data-privacy .privacy-header {
	text-align: center;
	color: #000;
	display: flex;
    align-items: center;
    justify-content: center;
}
.data-privacy .privacy-header img {
	height: 60px;
	margin-right: 30px;
}
.data-privacy .privacy-header span {
	font-size: 34px;
}
.data-privacy .privacy-item {
	padding: 10px;
	display: grid;
	grid-template-columns: 100px auto 300px;
	align-items: center;
}
.data-privacy .privacy-item p {
	padding: 0 20px 0 40px;
}
.data-privacy .contine-button,
.data-privacy .privacy-item a {
	padding: 15px 30px;
    border-radius: 4px;
	color: #fff;
	cursor: pointer;
	border: none;
	text-align: center;
}
.data-privacy .btn-wrapper {
	text-align: center;
}
.data-privacy .contine-button {
	width: 300px;
	font-size: 28px;
	margin-top: 40px;
}
.data-privacy .contine-button[disabled] {
	cursor: not-allowed;
	opacity: .7;
}

/* Below two css are to hide additional video info from videoJs player*/
.vjs-control-bar, .vjs-track-settings-colors, .vjs-hidden, .vjs-control-text {
	visibility: hidden;
}
.vjs-text-track-display {
	display: none;
}


/* website login page */
#website-login {
	position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    background: black;
	z-index: 200;
	display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
	align-items: center;
	padding: 40px 0;
}

#website-login .website-login-cntnt {
	text-align: center;
}

#website-login a {
	cursor: pointer;
	color: white;
	text-decoration: none;
}
#website-login .logoHolder{
	padding: 0;
	margin-bottom: 40px;
}

#website-login .redirect-message {
	font-size: 0.7em;
}

#previewVideo .vjs-loading-spinner {
	display: none !important;
}

/* ---------EPG LAYOUT--------- */
.EPG-layout {
	overflow-x: scroll;
	border-collapse: collapse;
	--epg-name-width: 300px;
	--epg--current-time-width: 260px;
	--epg--col-width: 360px; /* 60 mins, 1px == 10 seconds */
	--epg--col-height: 90px;
	--epg--show-des-height: 130px;
	--epg-background-color: rgb(40, 49, 53);
	--epg-time-bar-background-color: rgb(70, 85, 94);
	--epg-menu-title-background-color: rgb(54, 68, 75);
	--epg-video-tile-background-color: rgb(55, 109, 111);
	--epg-video-desc-tile-background-color: rgb(39, 55, 68);
	--epg-font-secondary-color: rgb(159, 172, 181);
	background-color: var(--epg-background-color);
	height: calc(100vh - var(--ctv_nav_height));
}
.non-section-content .EPG-layout {
	height: 100vh;
}
.EPG-layout .channel-wrapper {
	background-color: var(--epg-background-color);
	font-size: 16px;
	width: 100%;
}
.EPG-layout .channel-wrapper .channel-link {
	color: #fff;
	cursor: pointer;
	display: inline-block;
	position: sticky;
	left: 0;
	z-index: 2;
}
.EPG-layout .epg-header {
	background-color: #5f7485;
	font-size: 20px;
	position: sticky;
	top: -1px;
	z-index: 3;
}
.EPG-layout .channel {
	width: 100%;
	background-color: #343940;
}
.EPG-layout .channel .channel-name {
	/* position: absolute; */
	/* left: 0; */
	width: var(--epg-name-width);
	box-sizing: border-box;
	background-color: var(--epg-menu-title-background-color);
	padding: 10px 30px;
	height: var(--epg--col-height);
	display: flex;
	justify-content: start;
	align-items: center;
	border-top: 1px solid rgb(61, 75, 81);
	z-index: 90;
	overflow: hidden;
}
.EPG-layout .channel.des-open,
.EPG-layout .channel.des-open .channel-name {
	height: 222px;
}
.EPG-layout .mobile .channel.des-open {
	position: relative;
}
.EPG-layout .channel .channel-name .channel-number {
	font-size: 34px;
	margin-right: 30px;
}
.EPG-layout .channel .channel-name .channel-info {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
	-webkit-box-orient: vertical;
}
.EPG-layout .epg-header .current-time {
	position: absolute;
	left: 0;
	width: var(--epg--current-time-width);
	padding: 10px 30px;
	box-sizing: border-box;
	height: 50px;
	background-color: var(--epg-time-bar-background-color);
	color: rgb(118, 239, 239);
	z-index: 90;
}

.EPG-layout .channel-programes {
	/* margin-left: var(--epg-name-width); */
	background-color: var(--epg-background-color);
	/* height: 70px; */
}
.EPG-layout .epg-header .time-head {
	margin-left: var(--epg--current-time-width);
	position: relative;
	background-color: var(--epg-time-bar-background-color);
}
.EPG-layout .epg-header .time-head .time {
	background-color: var(--epg-time-bar-background-color);
	height: 50px;
	min-width: var(--epg--col-width);
	max-width: var(--epg--col-width);
	display: table-cell;
	padding: 10px;
	box-sizing: border-box;
	position: relative;
	color: var(--epg-font-secondary-color)
}
.EPG-layout .epg-header .time::before {
	border-left: 0.5px dotted #000;
	content: "";
	position: absolute;
	top: 0;
	height: 50px;
	left: calc(var(--epg-name-width) - var(--epg--current-time-width));
}
.EPG-layout .epg-header .time-line {
	position: absolute;
  top: 0px;
}
.EPG-layout .epg-header .time-line-h {
	width: 12px;
	height: 12px;
	background: #4dffe1;
	display: block;
	border-radius: 6px;
}
.EPG-layout .epg-header .time-line-t {
	height: 38px;
	border-left: 2px solid rgb(118, 239, 239);;
	display: block;
	margin-left: 5px;
}
.EPG-layout .channel .channel-programes .show {
	/* display: table-cell; */
	display: inline-block;
	font-size: 18px;
	border: 1px solid rgb(47, 56, 60);
	border-collapse: collapse;
	vertical-align: middle;
	cursor: pointer;
	position: absolute;
	margin-left: var(--epg-name-width);
	background-color: var(--epg-background-color);
}
.EPG-layout .channel .channel-programes .show.same-show {
	background-color: var(--epg-video-tile-background-color);
}
.EPG-layout .channel .channel-programes .show .show-title-wrapper {
	width: var(--epg--col-width);
	padding: 5px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	height: var(--epg--col-height);
	text-align: left;
}
.EPG-layout .channel .channel-programes .show .show-title-wrapper .show-subtitle,
.EPG-layout .channel .channel-programes .show .show-title-wrapper .show-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: calc(var(--epg--col-width) - 20px);
}
.EPG-layout .channel .channel-programes .show .show-title-wrapper .show-subtitle {
	font-size: 14px;
	color: #adb3ad;
}
.EPG-layout .show-description {
	height: var(--epg--show-des-height);
	width: calc(100% - var(--epg-name-width));
	background-color: var(--epg-video-desc-tile-background-color);
	position: absolute;
	left: var(--epg-name-width);
	top: calc(var(--epg--show-des-height) - 4px);
}
.EPG-layout .show-description .wrapper {
	box-sizing: border-box;
	margin: 0 !important;
	outline: 0;
	padding: 15px;
	height: 100%;
	width: calc(100vw - var(--epg-name-width));
	display: flex;
	justify-content: start;
	align-items: center;
	position: sticky;
    left: var(--epg-name-width);
}
.EPG-layout .show-description .wrapper .img-video{
	height: 100%;
	width: 150px;
	padding: 0;
	margin-right: 20px;
}
.EPG-layout .show-description .wrapper > div:nth-child(2) {
	height: 100%;
	flex: 1
}
.EPG-layout .show-description .wrapper .show-title {
	font-size: 22px;
	color: #fff;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
	-webkit-box-orient: vertical;
}
.EPG-layout .show-description .wrapper .show-time {
	padding-bottom: 10px;
  	color: var(--epg-font-secondary-color);
}
.EPG-layout .show-description .wrapper .show-subtitle {
	color: var(--epg-font-secondary-color);;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	-webkit-box-orient: vertical;
}
.EPG-layout .show-description .wrapper .play-icon {
	z-index: 10;
	position: absolute;
	left: calc(50% - 12px);
	background-color: #fff;
	top: calc(50% - 12px);
	border-radius: 50%;
  padding: 2px;
	width: 25px;
}
.EPG-layout .preview {
	height: 50%;
  background: black;
}
.EPG-layout .preview .background {
	height: 100%;
  width: 100%
}
.EPG-layout .preview .preview-description {
	width: 100%;
  height: 100%;
	background: rgba(0,0,0,0.7);
	display: flex;
}
.EPG-layout .preview .preview-description .ch-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
}

.EPG-layout .preview .preview-description .ch-title img {
	width: 100%;
    height: 100%;
    object-fit: fill;
}

.EPG-layout .preview .preview-description .ch-title.landscape img {
    width: 250px;
    height: 140px;
    object-fit: cover;
}
.EPG-layout .preview .preview-description .ch-title span {
	font-size: 22px;
}
.EPG-layout .preview .preview-description .ch-show-name {
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;
	padding: 20px 30px;
	box-sizing: border-box;
}
.EPG-layout .preview .preview-description .ch-show-name .title {
	font-size: 22px;
}
.EPG-layout .preview .preview-description .ch-show-name .sub-title {
	font-size: 16px;
  color: #adb3ad;
}
.EPG-layout .preview .preview-description .ch-show-name .sub-title.item-description {
	font-size: 18px;
	margin-top: 10px;
	-webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.EPG-layout .main-content {
	position: fixed;
	overflow: scroll;
	height: calc(50% - var(--ctv_nav_height) / 2);
	width: 100%;
	background-color: var(--epg-background-color);
}
.non-section-content .EPG-layout .main-content {
	height: 50%;
	top: 50%;
}
.EPG-layout .main-content.mobile{
	top: var(--nav-height);
	height: calc(100vh - var(--nav-height));
}
.EPG-layout .main-content a.show-link {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer;
	color: #fff;
}
.simulator-route-container .EPG-layout .preview .preview-description {
	top: 0px;
}
/* Interstitial style */
#overlay.interstitial {
	z-index: 90;
	background-color: rgba(0,0,0,1)
}

#overlay.interstitial .detail-view{
	margin-top: 30px;
}

#overlay.interstitial .summaryHolder {
	display: block;
}
#overlay.interstitial .detail-view-cls .ctaHolder {
	padding: 0;
}


/* Geofence modal */
#geofence-modal{
	z-index: 99;
}

.breakSpaces {
    white-space: break-spaces;
}

/* Voucher modal */
.voucher-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}
.voucher-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 30px;
  border: 1px solid #888;
	width: 30%;
	color: black;
	border-radius: 4px;
}
.voucher-modal-content .title {
	font-size: 22px;
	text-align: center;
}
.voucher-modal-content input {
	display: block;
	font-size: 18px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	margin: 20px 0;
}
.voucher-modal-content .footer {
	text-align: right;
}
.voucher-modal-content .footer button {
	font-size: 20px;
	padding: 10px;
	border-radius: 4px;
	margin-left: 10px;
	cursor: pointer;
}
.voucher-modal-content p {
	font-size: 16px;
	text-align: center;
}
.voucher-modal-content p.red {
	color: red;
}
.voucher-modal-content p.green {
	color: green;
}
.cc-div {
	display: flex;
    padding: 5px 20px;
    margin: 15px 0;
    align-items: center;
    justify-content: flex-start;
}

.cc-div > span {
	margin-right: 15px;
	font-size: 38px;
}

.cc-div .switch-slider-wrapper {
	width: 90px;
    height: 40px;
}
.cc-div .switch-slider-wrapper>span:before {
	height: 32px;
    width: 32px;
}

/* Subtitle styling */
.vjs-text-track-display > div > div > div {
	background-color: rgba(60,60,60,0.6) !important;
	padding: 0 10px !important;
	border-radius: 5px !important;
	display: inline-block !important;
}

.reset-page {
	color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
	min-height: 400px;
	background-color: #f1f1f5;
}

.reset-page .reset-content {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.password-div {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
}

.reset-page .password-input {
	padding: 10px 20px;
    border-radius: 10px;
    border: none;
    width: 100%;
    margin: 0;
    font-size: 17px;
    box-sizing: border-box;
	outline: none;
}

.reset-page .password-submit {
    width: 300px;
    padding: 10px 15px;
    border-radius: 5px;
    border: 0;
	margin: 20px 0 0;
	font-size: 15px;
	background-color: #144B13;
    border-color: #144B13;
	color: white;
}

.reset-page h4{
	margin: 0;
	font-weight: normal;
	font-size: 35px;
}

.reset-page .reset-footer {
	font-size: 15px;
    text-align: center;
	padding-bottom: 15px;
}

.reset-page .reset-footer a {
	margin: 0 20px;
	text-decoration: none;
	color: #0F7FFE;
}




/* -----------------------------*/
/* ----------   TVOD   -------- */
/* ---------------------------- */
.stream-block-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: #191414f5;
	flex-direction: column;
	z-index: 100;
	color: white;
	position: absolute;
	padding: 20px;
  box-sizing: border-box;
	top: 0;
}
.simulator-route-container .stream-block-modal .stream-modal-title {
	font-size: 60px;
}
.simulator-route-container .stream-block-modal span {
	font-size: 40px;
}
.simulator-route-container .stream-block-modal .stream-modal-dismiss {
	width: 700px;
	height: 100px;
	margin: 50px 0;
	font-size: 24px;
	border-radius: 8px;
	cursor: pointer;
}
.tvod-interstitial .base,
.tvod-interstitial .base img {
	height: 100vh;
	width: 100%;
	background-color: black;
}
.tvod-interstitial .base img {
	height: 100%;
	object-fit: fill;
}
.tvod-interstitial .base .no-background {
	background-color: black;
	height: 100vh;
	width: 100%;
}
.tvod-interstitial .base #previewVideo {
	width: 100%;
	z-index: 0;
}
.tvod-interstitial .base #previewVideo #previewShadow {
	box-shadow: none;
	background-image: linear-gradient(to right, rgb(0,0,0,0.6), rgb(0,0,0,0.3), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0));
}
.tvod-interstitial .description {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background-image: linear-gradient(to right, rgb(0,0,0,0.6), rgb(0,0,0,0.3), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0));
}
.tvod-interstitial .description .description-wrapper {
	padding: 60px 0px 60px 75px;
	width: 45%;
	box-sizing: border-box;
	height: 100%;
	font-size: 80px;
	/* using % in fontsize for every element inside this wrapper */
}
.tvod-interstitial.section-interstitial .description .description-wrapper {
	padding: 120px 0px 60px 75px;
}
.tvod-interstitial .description .description-wrapper .titles {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}
.tvod-interstitial .description .description-wrapper .titles .title {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-bottom: 10px;
	text-shadow: 0px 0px 10px #000000;
}
.tvod-interstitial .description .description-wrapper .titles .subtitle {
	font-size: 40%;
	padding: 10px 0;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-shadow: 0px 0px 10px #000000;
}
.tvod-interstitial .description .description-wrapper .titles .badge{
	font-size: 35%;
	padding: 0px 20px 2px 20px;
	background-color: white;
	color: black;
	border-radius: 8px;
	max-width: fit-content;
	font-weight: 600;
}
.tvod-interstitial .description .description-wrapper .meta-data {
	padding-bottom: 50px;
	font-size: 40%;
	text-shadow: 0px 0px 10px #000000;
}
.tvod-interstitial .description .description-wrapper .meta-data span {
  padding-right: 20px;
}
.tvod-interstitial .description .description-wrapper .meta-data p {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
  margin: 5px 0;
}
.tvod-interstitial .description .tvod-summary {
	margin: 20px 0;
	font-size: 45%;
	text-shadow: 0px 0px 10px #000000;
}
.tvod-interstitial .description .tvod-summary span {
	display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.tvod-interstitial.section-interstitial .description .tvod-summary span {
	-webkit-line-clamp: 7;
}
.tvod-interstitial .description .item-play {
	position: absolute;
	bottom: 40px;
	font-size: 24px;
	display: flex;
	align-items: center;
	font-weight: bold;
}

.tvod-interstitial .description .item-play > span {
	width: max-content;
	min-width: 200px;
	height: 40px;
	text-align: center;
	border-radius: 8px;
	margin-right: 30px;
	cursor: pointer;
	padding: 20px 50px;
	background-color: black;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 10px rgb(0 0 0 / 65%);
}
.tvod-interstitial .description .item-play.free > span {
	flex-direction: row;
}
.tvod-interstitial .description .item-play > span sub {
	display: block;
	font-size: 60%;
}
.tvod-interstitial .description .item-play .save-icon img {
	vertical-align: middle;
	height: 40px;
	background-color: black;
	padding: 20px;
	border-radius: 8px;
	cursor: pointer;
	width: 40px;
	text-align: center;
	box-shadow: 0px 0px 10px rgb(0 0 0 / 65%);
}
.tvod-interstitial .description .item-play .arrow-right {
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-left: 15px solid white;
	display: inline-block;
	margin-left: 15px;
	margin-top: 4px;
	vertical-align: middle;
}
.tvod-interstitial .more-sub-wrapper {
	position: absolute;
	top: 0;
	padding: 60px 75px 30px 75px;
	font-size: 36px;
	overflow: scroll;
	height: 100%;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.tvod-interstitial .description .item-play > span:hover,
.tvod-interstitial .sub-details .purchase-button:hover,
.tvod-interstitial .more-purchases .purchase-button:hover {
	transform: scale(1.1);
}
.ctv-container .tvod-interstitial .focusable:focus-visible {
	border: 2px solid yellow !important;
	box-shadow: 0 0 0 2px yellow !important;
	outline: none;
}
.tvod-interstitial.section-interstitial .more-sub-wrapper {
	padding-top: 120px;
}
.tvod-interstitial .sub-details .purchase-button,
.tvod-interstitial .more-purchases .purchase-button {
	background-color: black;
	padding: 20px 50px;
	border-radius: 8px;
	font-size: 24px;
	width: 400px;
	margin: 10px 0;
	cursor: pointer;
	height: 40px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	box-shadow: 0px 0px 10px rgb(0 0 0 / 65%);
}
.tvod-interstitial .sub-details .purchase-button.extra,
.tvod-interstitial .more-purchases .purchase-button.extra {
	margin: 40px 0;
}
.tvod-interstitial .sub-details .purchase-button sub,
.tvod-interstitial .more-purchases .purchase-button sub {
	font-size: 60%;
}
.tvod-interstitial .more-ways {
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 75px;
  font-size: 36px;
	box-sizing: border-box;
	text-align: center;
	background-color: #1d1b1bc9;
}
.tvod-interstitial .ctv-poilcy {
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
.tvod-interstitial .ctv-poilcy h3 {
	font-size: 80px;
}
.tvod-interstitial .ctv-poilcy p {
	font-size: 40px;
}
.tvod-interstitial .policy-wrapper {
	font-size: 24px;
	text-align: center;
}
.tvod-interstitial .policy-wrapper .termsOfUse {
	position: relative;
}
.tvod-interstitial .login-page {
	position: absolute;
	top: 0;
	width: 100%;
}
.tvod-interstitial .login-page .termsOfUse {
	position: absolute;
}
/* -----------------------------*/
/* ----------   TVOD   -------- */
/* ---------------------------- */

/* ON Boarding Screen */
.onboarding-screen .first-layer {
	height: 100vh;
	background: black;
}
.onboarding-screen img.big-image {
	width: 100%;
	object-fit: fill;
	height: 100%;
}
.onboarding-screen .swiper-container-horizontal>.swiper-pagination-bullets {
	bottom: 200px;
}
.onboarding-screen .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	width: 16px;
	height: 16px;
	opacity: 0.7;
}
.onboarding-screen .second-layer {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-image: linear-gradient(to top, rgb(0,0,0,0.6), rgb(0,0,0,0.3), rgb(0,0,0,0));
}
.onboarding-screen .second-layer .screen-item {
	width: 30%;
	margin: auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
	box-sizing: border-box;
}
.onboarding-screen .second-layer .screen-item .title {
	font-size: 40px;
}
.onboarding-screen .second-layer .screen-item .summary {
	font-size: 32px;
}
.onboarding-screen .second-layer .small-image {
	width: 100%;
	max-height: 200px;
}
.onboarding-screen .second-layer p {
	text-shadow: 0px 0px 10px #000000;
	text-align: center;
}
.onboarding-screen .next-btn {
	position: absolute;
	width: 20%;
	left: 40%;
	font-size: 28px;
	height: 80px;
	bottom: 80px;
	z-index: 9;
	padding: 10px;
	cursor: pointer;
	padding: 10px;
  box-sizing: border-box;
}
.onboarding-screen .skip-btn {
	position: absolute;
	font-size: 32px;
	top: 40px;
	right: 40px;
	z-index: 9;
	cursor: pointer;
}
.onboarding-screen.login-page {
	background: black;
	padding: 40px;
	height: 100vh;
	box-sizing: border-box;
}
.onboarding-screen.mobile-view .second-layer .screen-item {
	width: 100%;
}
.onboarding-screen.mobile-view .next-btn {
	width: 80%;
	left: 10%;
}
.onboarding-screen.mobile-view .second-layer .screen-item .title {
	font-size: 22px;
}
.onboarding-screen.mobile-view .second-layer .screen-item .summary {
	font-size: 18px;
}
/* ON Boarding Screen */
/* -----------------------------------*/
/* ----------   Device OTP   -------- */
/* ---------------------------------- */
.otp-input-parent {
	display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
	background-color: #000;
	flex-direction: column;
}

.otp-input-parent .submit-btn {
	margin-top: 30px;
    padding: 11px 30px;
    border-radius: 10px;
    font-size: 22px;
	transition: transform .2s;
	border: none;
	color: white;
}

.otp-input-parent .submit-btn:disabled {
	opacity: 0.6;
	cursor: default;
    color: rgba(255, 255, 255, 0.46);
}

.otp-input-parent .submit-btn:not(:disabled):hover {
	transform: scale(1.2)
}

/* -----------------------------------*/
/* ----------   Device OTP   -------- */
/* ---------------------------------- */
